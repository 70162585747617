<template>
  <div class="creatmember">
      <Pagenationtopimg />
      <Pagenationtop :pagenation_title="pagenation_title" path="creatmember" />
      <div class="main-container">
        <div class="content-container">
          <div class="top-title">
            <div class="canvas"></div> <p>我們將發送手機驗證碼至您的手機內</p>
          </div>
          <div class="content one">
            <span class="ps-title"><span class="after">*</span>驗證手機</span>
            <div class="sub-container"></div>
            <input   
            ref="phone"
            v-model="user.account" 
            class="input" 
            type="text" 
            placeholder="請輸入手機號碼">
            <v-btn :disabled="sms_disabled" @click="phoneSMS">
              {{sms_button_text}}
            </v-btn>
            <!-- <button  :disabled="sms_disabled" @click="phoneSMS">
            </button> -->
          </div>
          <div class="content two">
            <span class="ps-title"><span class="after">*</span>簡訊驗證</span>
            <input v-model="user.sms_code" class="input" placeholder="請輸入6碼驗證碼" type="text">
          </div>
          <div class="content">
            <span class="ps-title"><span class="after">*</span>設定新密碼</span>
            <input v-model="user.password" class="input" type="text" placeholder="請輸入6-20碼英數字">
          </div>
          <div class="content">
            <span class="ps-title"><span class="after">*</span>確認新密碼</span>
            <input v-model="user.password2" type="text" class="input" placeholder="請輸入6-20碼英數字">
          </div>
          <div class="content">
            <span class="ps-title"><span class="after">*</span>姓名</span>
            <input v-model="user.name" type="text" class="input" placeholder="請輸入姓名">
          </div>
          <div class="content">
            <span class="ps-title phone-title">連絡電話</span>
            <input v-model="user.tel" type="text" class="input" placeholder="請輸入市話或手機(例:0911999999、04-1234567)">
          </div>
          <div class="content">
            <span class="ps-title"><span class="after">*</span>電子信箱</span>
            <input v-model="user.email" type="text" class="input" placeholder="請輸入電子信箱">
          </div>
          <div class="content address">
            <span class="ps-title"><span class="after">*</span>聯絡地址</span>
            <div class="select-container">
                <v-select
                  :items="cityname"
                  v-model="user.city"
                  menu-props="auto"
                  label="請選擇"
                  hide-details
                  single-line
                  outlined
                  dense
                  background-color="white"
                ></v-select>
                <v-select
                  :items="townname"
                  v-model="user.town"
                  menu-props="auto"
                  label="請選擇"
                  hide-details
                  single-line
                  outlined
                  dense
                  background-color="white"
                ></v-select>
            </div>
            <input 
                  v-model="user.address" type="text" class="input" placeholder="請輸入詳細地址">
          </div>
          <div class="content number">
            <span class="ps-title"><span class="after">*</span>驗證碼</span>
            <div class="sub-container">
              <input 
                  v-model="user.acode" type="text"  class="input" placeholder="請輸入4碼驗證碼">
              <div class="number">
                <img :src="captcha_img" alt="">
              </div>
              <button @click="getCaptcha">
                <img src="../../../public/img/creatmember/replay.png" alt="">
              </button>
            </div>
          </div>
        </div>
        <div class="check">
          <button @click="postMemberRegister" class="a" to="/">
              確定
          </button>
        </div>
      </div>
  </div>
</template>

<script>
import Pagenationtopimg from '../../components/pagenationimg/index.vue'
import Pagenationtop from "../../components/pagenationtop/index.vue"
import cityName_data from "@/assets/citydata.json";
import qs from 'qs'


export default {
  data(){
    return{
      mask_time:300,//驗證碼時間
      sms_button_text:'取得驗證碼',
      sms_disabled: false,
      pagenation_title:[
        '會員註冊'
      ],
      captcha_img:'',
      cityname: [],
      townname:[],
      user:{
        acode:'',
        sms_code:null,
        account:null,
        password:'',
        password2:'',
        name:'',
        tel:'',
        email:'',
        city:'',
        town:'',
        address:'',
      },
      info:''
    }
  },
  components:{
    Pagenationtopimg,
    Pagenationtop,
  },
  watch: {
    "user.city": function() {
      this.townname.splice(0,this.townname.length)
      let index = this.cityname.indexOf(this.user.city)
      cityName_data[index].AreaList.forEach(element => {
        this.townname.push(element.AreaName)
      });
    }
  },
  mounted(){
    let vm = this
    if(this.$session.get('mask_time')){
      console.log('masktime')
          vm.masktimeout = setInterval(this.maskTime, 1000),
          vm.timeout = setTimeout(this.timeUp, 60000),
          vm.sms_button_text = "驗證碼重發("+vm.mask_time+")",
          vm.sms_disabled = true
    }
    this.$store.commit('closeOverlay')
    this.getCaptcha()
    cityName_data.forEach(element => {
      this.cityname.push(element.CityName)
    });
    let index = this.cityname.indexOf(this.user.city)
    if(this.user.city === ''){
      console.log("city null")
    }
    else{
      cityName_data[index].AreaList.forEach(element => {
        this.townname.push(element.AreaName)
      });
    }

  },
  methods:{
    getCaptcha(){
      this.axios.post('/api/member/captcha_show')
      .then(response => (
        this.captcha_img = response.data.message,
        this.info = response.data))
      .catch(function (error){
        console.log(error)
      })
    },
    //手機驗證碼
    phoneSMS(){
      this.$session.set('mask_time',true)
      let vm = this
      if(vm.user.account == null){
        alert('請輸入手機號碼')
        vm.$refs.phone.focus()
      }
      else{
        vm.axios.post('/api/member/to_phoneSMS', qs.stringify({
          phone: vm.user.account
        }))
        .then( function(res){
          console.log(res)
          if(res.data.status){
            vm.masktimeout = setInterval(this.maskTime, 1000),
            vm.timeout = setTimeout(this.timeUp, 60000),
            vm.sms_button_text = "驗證碼重發("+vm.mask_time+")",
            vm.sms_disabled = true
          }
          else{
            alert(res.data.msg)
          }
        })
      }
    },
    maskTime(){
      console.log(123)
      this.mask_time = this.mask_time-1
      this.sms_button_text = "驗證碼重發("+this.mask_time+")"
    },
    timeUp(){
      this.sms_button_text = "取得驗證碼"
      this.sms_disabled = false
      this.$session.remove('mask_time')
      clearTimeout(this.timeout)
      clearTimeout(this.masktimeout)
    },
    postMemberRegister(){
      console.log(123)
      let vm = this
      this.axios.post('/api/member/register',qs.stringify({
          SMS_code:this.user.sms_code,
          acode:this.user.acode,
          lang :this.$store.state.lang ,
          account: this.user.account,
          password:this.user.password,
          password2:this.user.password2,
          name:this.user.name,
          tel:this.user.tel,
          email:this.user.email,
          city:this.user.city,
          town:this.user.town,
          address:this.user.address
        }),
          )
      .then(function(response){
          console.log(response)
          if(response.data.status === true){
            vm.info = response,
            console.log(response),
            vm.Login()
          }
          else{
            alert(response.data.msg)
          }
        } )
      .catch(function (error){
        alert('會員註冊失敗，請查看是否有漏打')
          console.log(error)
      })
    },

    Login(){
        let vm = this
        console.log('Login')
        if(this.user.account != null & this.user.password != null){
            this.axios.post('/api/member/login',qs.stringify({
                lang: this.$store.state.lang ,
                account: this.user.account ,
                password: this.user.password
            }))
            .then(function(response){
                console.log(response.data.status)
                if(response.data.status == true){
                    vm.loadingMemberdata(response)
                }
            } )
            .catch(function (error){
                console.log(error)
            })
        }
        else{
            alert('帳號密碼不可為空')
        }
    },

    loadingMemberdata(response){
        this.$session.set('memberLogin',true),
        this.$session.set('memberName',response.data.member_data.name),
        this.$session.set('memberAccount',response.data.member_data.account)
        ,
        this.$session.set('memberId',response.data.member_data.userid)
        ,
        this.$session.set('memberTel',response.data.member_data.tel)
        ,
        this.$session.set('memberNumbering',response.data.member_data.numbering)
        ,
        this.$session.set('memberSex',response.data.member_data.sex)
        ,
        this.$session.set('memberEmail',response.data.member_data.email)
        ,
        this.$session.set('memberBirthday',response.data.member_data.birthday)
        ,
        this.$session.set('memberCity',response.data.member_data.city)
        ,
        this.$session.set('memberTown',response.data.member_data.town)
        ,
        this.$session.set('memberAddress',response.data.member_data.address)
        alert('['+this.$session.get('memberName')+']您好~感謝您的加入~前往我的優惠卷查看專屬註冊禮!!')
        this.$router.push({name:`MemberCenter` , 
        params: {id:3}})
    },
  }
}
</script>

<style>

</style>